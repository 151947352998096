import { defineStore, getActivePinia, } from 'pinia'
import fetch from '@/assets/js/src/util/fetch'
import { getDefaultHeaders, } from '@/assets/js/src/util/fetch/defaultHeaders'
import { checkResponse, handleException, } from '@/assets/js/src/util/apiTools'
import { useLangStore, } from '@/assets/js/src/modules/lang/_pinia/lang'

export const useHomeStore = defineStore('home', {
    state: () => ({
        meta: {},
        inAction: false,
    }),
    actions: {
        async fetchHomeMeta () {
            let activePinia = getActivePinia()
            let langStore = useLangStore(activePinia)

            try {
                // Api-Request senden
                let useCache = true
                let apiResponse = await fetch({
                    url: '/api/home',
                    options: {
                        headers: getDefaultHeaders({
                            useCache,
                        }),
                    },
                    useCache,
                    includeTagsInId: true,
                    tags: [
                        `locale.${langStore.locale}`,
                    ],
                })

                // Prüfen der Api Antwort
                if (checkResponse(apiResponse)) {
                    throw new Error('Fehler in Api-Response')
                }

                this.meta = apiResponse.data

            } catch (e) {
                handleException(e, false, false)
            }
        },
        async fetchGraphic () {
            let activePinia = getActivePinia()
            let langStore = useLangStore(activePinia)

            try {
                // Api-Request senden
                let useCache = true
                let apiResponse = await fetch({
                    url: '/api/home/graphic',
                    options: {
                        headers: getDefaultHeaders({
                            useCache,
                        }),
                    },
                    useCache,
                    includeTagsInId: true,
                    tags: [ 'locale.' + langStore.locale, ],
                })

                // Prüfen der Api Antwort
                if (checkResponse(apiResponse)) {
                    throw new Error('Fehler in Api-Response')
                }

                return apiResponse.data.graphic
            } catch (e) {
                handleException(e, false, false)
            }
        },
    },
})
